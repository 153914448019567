header {
  @include clearfix;
  background: transparent;
  position: relative;
  padding: 0;
  opacity: 0;
  @include breakpoint($desktop) {
    z-index: 499;
    width: 100%;
    position: fixed;
  }
}

.no-js header {
  opacity: 1;
}

.logged-in header {
  @include breakpoint($desktop) {
    position: relative;
  }
}

// Logo
#block-block-1, #block-block-9, #block-block-13, #block-block-14 {
  z-index: 2;
  @include breakpoint($mobile) {
    margin-top: -44px - 35;
    background: black;
  }

  a {
    display: block;
  }

  img {
    padding-left: 6px;
    max-width: 100px;

    @include breakpoint($mobile) {
      image-rendering: auto;
      max-width: 42px;
    }
  }

  padding: 0 0 0 15px;

  @include breakpoint($desktop) {
    position: absolute;
    top: 23px;
    left: -3px;
//    padding: 27px 0 27px 15px;
  }
  p {
    margin: 0;
  }
}

#block-block-9, #block-block-13, #block-block-14 {
  img {
    padding-left: 6px;
    max-width: 65px;

    @include breakpoint($mobile) {
      image-rendering: auto;
      max-width: 42px;
    }
  }
}

//#block-block-1 {
//  img {
//    @include breakpoint($desktop) {
//      max-width: 70%;
//    }
//    @include breakpoint($desktop-large) {
//      max-width: 100%;
//    }
//
//  }
//}

.menu-dropper {
  margin-top: 42px;
  display: none;
  i {
    color: #8d8d8d;
    font-size: 24px;
  }
}


#block-menu-menu-social-media {
  float: right;
  margin-right: 6px;

  > ul {
    li {
      position: relative;
      display: inline;
    }

    a {
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url(../images/social-media.png);
      border:0;
      font: 0/0 a;
      text-shadow: none;
      color: transparent;
      margin-right: 6px;
      vertical-align: top;
    }
  }

  .icon-fb {
    background-position: -82px 0;
  }
  .icon-tw {
    background-position: 0 0;
  }
  .icon-gp {
    background-position: -123px 0;
  }
}



/* Media query START of: $small-only **********************************************/
@include breakpoint($mobile) {
  .header-top {
    margin: 0 auto;
  }

  .menu-dropper {
    display: inline;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
/* Media query END of: $small-only ************************************************/

.logged-in .headermain {
  background: $color-dark-grey;
}

.headermain {
  position: relative;
  @include breakpoint($desktop) {
    height: 102px;
  }

  img {
    width: 100%;
    transition: width 0.3s ease-in-out;
  }

  .row {
    position: relative;
  }
}

.headermain.solid {
  // Only once scrolled
  background-color: #1a1a1a;
  transition: background-color 0.3s ease;

  img {
    width: 47%;
    transition: width 0.3s ease-in-out;
  }
}

.headermain.solid #block-block-1,
.headermain.solid #block-block-9,
.headermain.solid #block-block-13,
.headermain.solid #block-block-14 {
  img {
    width: 70%;
  }
}

.headerband {
  @include clearfix;
  //border-bottom: 1px solid #696969;
  padding: 9px 0 7px;

  @include breakpoint($desktop) {
    padding: 9px 0;
  }
  background: black;
}

// Top level menu
#block-menu-block-2{
  @include breakpoint($mobile) {
    border-top: 1px solid #696969;
    padding: 6px 10px 0;
    margin-top: 43px;
    background: #101010;
  }

  font-weight: 500;
  text-transform: uppercase;
  color: #8d8d8d;
  font-size: 11px;

  @include breakpoint($desktop) {
    font-size: 13px;
    float: left;
    padding-left: 15px;
  }
  li {
    position: relative;
    display: inline-block;
    padding: 0 11px;
    margin: 0 0 0 -4px;
    vertical-align: middle;
  }
  li:first-child a {
    padding-left: 0;
    margin: 0;
    @extend .hide-text;
  }
  li:first-child a:before {
    @extend .icon-home;
    @extend .fontello;
    font-size: 20px;
    color: #8d8d8d;
  }
  li a {
    text-decoration: none;
    color: #8d8d8d;
    font-weight: 500;
  }

  li.active-trail:after {
    position: absolute;
    content: "";
    border-bottom: 4px solid white;
    bottom: -11px;
    left: 0;
    width: 100%;
    z-index: 123;
  }
}

// Top level social media
#block-block-8 {
  max-width: 400px;
  @include breakpoint($desktop-large) {
    max-width: inherit;
  }

  a {
    color: #8d8d8d;
  }

  //display: none;
  li:nth-child(1),
  li:nth-child(2) {
    display: inline-block;
  }

  @include breakpoint($mobile) {
    max-width: 200px;
    position: absolute;
    z-index: 124;
    top: 6px;
    right: 0;
    text-align: right;
    font-size: 12px;
    li {
      display: none;
    }
  }

  @include breakpoint($desktop) {
    font-size: 13px;
    float: right;
    display: block;
    padding-right: 15px;

    li {
      display: inline-block;
    }
  }
  li {
    padding: 0 4px;
    margin: 0 0 0 -4px;
    vertical-align: middle;
    a {
      margin: 0 !important;
      vertical-align: bottom;
    }
  }
  li:nth-child(1), li:nth-child(2), li:nth-child(3) {
    color: #8d8d8d;
    padding-right: 24px;
  }

  li:nth-child(3):after {
    content: "\A";
    white-space: pre;
  }

  li:nth-child(1):before,
  li:nth-child(2):before{
    color: #8d8d8d;
    @extend .fontello;
    @extend .icon-phone-squared;
  }


  li:nth-child(3):before {
    color: #8d8d8d;
    @extend .fontello;
    @extend .icon-mail-alt;
  }

  li:last-child {
    padding-right: 0;
  }
}
