div.contextual-links-wrapper {
  z-index: 99;
  display: inherit !important;
}
.contextual-links-region:hover a.contextual-links-trigger {
  display: block;
}

.tabs {
  height: 38px;
}

ul.tabs.primary {
  @include breakpoint(max-width 1024px) {
    top: 0;
  }
  position: fixed;
  z-index: 498;
  margin: 0;
  padding: 0;
  border-bottom: none;
  @include clearfix;

  li {
    font-size: 18px;
    margin-right: -5px;
    a {
      font-family: "Open Sans","Segoe UI","Helvetica",sans-serif;
      background: #DEDEDE;
      border: none;
      font-weight: 300;
      padding: 5px 18px;
      color: black;
      margin-right: 0;
      display: inline-block;
      //border-bottom: 2px solid white;
    }
  }
  li.active a {
    background: white;
  }
  li:hover a {
    background: #0074BD;
    color: white;
  }
}

.tabs li {
  padding: 0;
}

.field-collection-view {
  margin: 0;
  padding: 0;
}

.field-collection-container {
  border: none;
  margin: 0;

}

.block-system-navigation {
  padding-top: 40px;
  clear: both;
}

#admin-menu {
  font-size: 12px;
}

.page-user .block-block-6 {
  display: none;
}

ul.pager {
  padding: 0;
  margin: 0;
}

.main-content .item-list-pager ul, #main-content .item-list-pager li:first-child {
  margin-left: 0;
}

.main-content .item-list-pager {
  //text-align: center;
  @include grid-column(4);
  font-size: 16px;
  margin-top: 10px;
  padding-bottom: 40px;

  li {
    text-transform: uppercase;
  }
  a {
    text-decoration: none !important;
  }

  .pager > li {
    //display: inline-block;
    margin: 0;
  }

  .previous a {
    padding-right: 10px;
  }

  .next a {
    padding-left: 10px;
  }
}

.path-user #main-content {
  padding-top: 200px;
}
