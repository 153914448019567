.screenreadable {
  border: 0px none;
  font: 0px/0 a;
  text-shadow: none;
  color: transparent;
}

.hideme {
  display: none;
}
.hide-text {
  border:0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}
.hide-text:before {
  font-size: 18px;
  color: #8d8d8d;
}


//@keyframes fadeInBody { from { background:white; } to { background: $color-dark-grey; } }

body {
  font-family: $font-body;
  color: #454545;
  //background: $color-dark-grey;
  background: white;

  //animation:fadeInBody ease-in 1;
  //animation-fill-mode:forwards;
  //animation-duration:1s;
}

h2 {
  font-size: 30px;
  font-weight: 700;

  @include breakpoint($desktop-huge) {
    font-size: 40px;
  }
  @include breakpoint($desktop) {
    padding-right: 20px;
  }


  color: #454545;
  font-family: $font-headings;
  overflow-wrap: break-word;
  word-wrap: break-word;
  //-webkit-hyphens: auto;
  //-ms-hyphens: auto;
  //-moz-hyphens: auto;
  //hyphens: auto;

  &:after {
    display: none;
  }
  &:before {
    display: block;
    content: "";
    width: 20px;
    border-bottom: 1px solid $color-gold;
    margin-top: 18px;
    margin-bottom: 20px;
  }
}

.goldh2 {
  &:before {
    display: none;
  }
}

h3,
.goldh2 {
  color: $color-gold;
  font-family: $font-headings;
  line-height: 1em;
  text-transform: uppercase;
  font-weight:700;
  margin-bottom: 15px;
  margin-top: 20px;

  font-size: 28px;
  @include breakpoint($desktop) {
    font-size: 35px;
  }

  &:after {
    display: block;
    content: "";
    width: 20px;
    border-bottom: 1px solid $color-gold;
    margin-top: 18px;
    margin-bottom: 20px;
  }
}

.field-name-field-title {
  font-family: $font-body;
  color: $color-gold;
  line-height: 1em;
  text-transform: uppercase;
  font-weight:700;
  margin-bottom: 15px;
  margin-top: 20px;

  font-size: 28px;
  @include breakpoint($desktop) {
    font-size: 35px;
  }
}

.field-name-field-sub-title {
  &:after {
    display: block;
    content: "";
    width: 20px;
    border-bottom: 1px solid $color-gold;
    margin-top: 13px;
    margin-bottom: 15px;
  }
}

h4 {
  text-transform: uppercase;
  font-size: 24px;
  //font-weight: 700;
  //font-family: $font-headings;
  margin-bottom: 15px;
}

p {
  margin-bottom: 22px;
  color: $color-dark-grey;
  font-weight: 400;
  font-size: 15px;
  @include breakpoint($desktop) {
    font-size: 17px;
  }
}

p.large {
  font-size: 19px;
  @include breakpoint($desktop) {
    font-size: 21px;
  }
}

.content .field-name-field-body ul {
  padding: 0;
}

.content .field-name-field-body ul li:before {
  content: "• ";
}

a.active {
  font-weight: normal;
}

b, strong {
  font-weight: 700;
}

.ds-banner {
  text-align: center;
}

#main-content {
  background: white;
  @include clearfix;
  padding: 0;
  //@include breakpoint($desktop) {
  //  padding-top: 41px;
  //}
}


p a {
  color: #09a7e0;
  text-decoration: underline;
}

.page-search {
  #main-content > ul {
    padding: 0 15px 20px;
  }
}

.row {
  @include grid-row();
}

#main-content {
  ul {
    padding-bottom: 20px;
  }

  li {
    padding-left: 36px;
    font-size: 17px;
    font-weight: 300;
    //color: #222;
  }
  ol {
    margin-left: 20px;
  }
  ol li {
    background: none;
    list-style-type: decimal;
    display: list-item;
    padding-left: 10px;
  }
}

sup {
  line-height: 0;
  vertical-align: middle;
  margin-bottom: 10px;
  display: inline-block;
}

div.video-filter {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 0 auto 20px;
  clear: both;
}
iframe.video-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #22BF7B;
}

.videocolumn {
  @include grid-column(12);
  position:relative;
  padding-bottom: 52.3333%;
  height: 0
}

.videostill {
  @include grid-column(12);
  height: 300px;
  overflow: hidden;
}

.arrow-link {
  color: $color-blue;
  text-transform: uppercase;
  border-bottom: 2px solid #09a7e0;
  font-weight: 600;
  padding-bottom: 3px;
  position: relative;

  &:after {
    position: absolute;
    content: "\e80c";
    right: -20px;
    font-family: "fontello";
    transform: rotate(90deg);
  }

  &:hover {
    text-decoration: none;
  }
}


// Styles mostly for the home pages
.ds-left {
  padding: 0 15px 20px;
  @include breakpoint($desktop-large) {
    padding: 0 0 0 15px;
    width: 25%;
    float: left;
  }
}
.ds-right {
  padding: 0 15px;
  @include breakpoint($desktop-large) {
    padding: 0 15px 0 0;
    margin-top: 70px;
    width: 75%;
    float: left;
  }
}

.large-number {
  color: $color-gold;
  font-family: $font-headings;
  font-weight: 800;
  line-height: 0.8em;

  font-size: 85px;
  @include breakpoint($desktop) {
    font-size: 113px;
  }


  //&:after {
  //  display: block;
  //  content: "";
  //  width: 20px;
  //  border-bottom: 1px solid $color-gold;
  //  margin-bottom: 20px;
  //}
}
.side-heading {
  color: $color-dark-grey;
  font-family: $font-headings;
  font-size: 32px;
  font-weight: 700;

  @include breakpoint($desktop) {
    font-size: 30px;
  }

  @include breakpoint($desktop-huge) {
    font-size: 50px;
  }

  .desktop-br:after {
    content: " ";
  }
  .desktop-br:after {
    @include breakpoint($desktop-large) {
      content: "\a";
    }
  }

}

.ds-left .field-name-field-main-title {
  text-transform: none;
}

blockquote {
  padding: 20px 20px;


  margin: 50px 50px 50px;
  @include breakpoint($desktop-full) {
    margin: 50px 0 50px;
  }

  @include breakpoint($desktop) {
    padding: 50px 68px;
  }

  background: #f4f4f4;
  font-family: $font-headings;
  font-weight: 400;
  position: relative;

  &:before {
    content: "\201C";
    line-height: 1em;
    color: #717171;
    position: absolute;

    font-size: 140px;
    left: -10px;
    top: -30px;
    @include breakpoint($desktop) {
      font-size: 290px;
      left: -70px;
      top: -20px;
    }
  }

  p {
    color: #6d6d6d;
    font-size: 17px;
    @include breakpoint($desktop) {
      font-size: 21px;
    }
  }
  p:last-child {
    font-family: $font-body;
    font-size: 17px;
    color: #454545;
    margin-bottom: 0;
  }
}

.service-links {
  padding: 70px 0;
  &:before {
    content: "Share this article";
    text-transform: uppercase;
    padding-right: 22px;
    @include breakpoint($mobile) {
      display: block;
    }

  }
  a {
    color: #ac9573;
    margin-right: 18px;
    text-decoration: underline;
  }
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}
.owl-theme .owl-dots .owl-dot span {
  width: 22px;
  height: 22px;
  margin: 32px 7px 5px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}


.field-name-field-tags {
  @include breakpoint($desktop) {
    display: inline;
    padding-left: 10px;
  }
  color: #50c388;
  a {
    text-decoration: underline !important;
  }
}

.left-hand-title {
  font-size: 38px;

  @include breakpoint($desktop-huge) {
    font-size: 50px;
  }

  line-height: 1em;
  color: #454545;
  font-family: $font-headings;
  font-weight: 700;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  &:after {
    display: none;
  }
  &:before {
    display: block;
    content: "";
    width: 20px;
    border-bottom: 1px solid $color-gold;
    margin-top: 18px;
    margin-bottom: 20px;
  }
}

.full-width-image img {
  width: 100%;
}

.owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
}
.owl-prev,
.owl-next {
  position: absolute;
  background-image: url(../images/owl-next-prev.png);
  width: 52px;
  height: 53px;
  margin-top: -15px;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.owl-prev {
  left: -10px;
}
.owl-next {
  right: -10px;
  background-position: right top;
}

.pager-load-more {
  padding-top: 50px !important;
  text-transform: uppercase;
  li {
    padding-left: 0 !important;
  }

  a {
    color: #09a7e0;
    border: 2px solid #09a7e0;
    font-size: 15px;
    font-weight: 700;
    padding: 12px 16px;
    border-radius: 24px;

    &:after {
      content: '\e80d';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      padding-left: 10px;
    }
  }
}

.js [data-appear-animation*="fade"] {
  opacity: 0;
}

.hypeanim {
  margin: 0 !important;
}

.grey-background {
  background: #3c3c3c;
  .field-name-field-title, p {
    color: white;
  }
}

.node-type-404-page.path-page-not-found {
  .field-type-text-with-summary {
    background: url(../images/cross_background.png);
  }

  h1 {
    font-size: 130px;
    font-weight: 700;
    color: $color-gold;
    &:after {
      content: "";
      display: block;
      width: 20px;
      border-bottom: 2px solid $color-gold;
      margin: 20px auto;
    }
  }
}


.node-type-404-page {
  .field-type-text-with-summary {
    padding: 115px 15px 65px;
  }

  .field-type-viewfield {
    @include grid-row();
    padding: 50px 15px;
  }
  
  .node-teaser {
    @include breakpoint($desktop) {
      width: 50%;
      height: 400px;
    }
  }

  h1 {
    margin-top: 40px;
    font-size: 50px;
    font-weight: 700;
    color: $color-gold;
    &:after {
      content: "";
      display: block;
      width: 20px;
      border-bottom: 2px solid $color-gold;
      margin: 30px auto;
    }
  }
  .field-type-text-with-summary {
    text-align: center;
  }
  p {
    font-size: 26px;
  }
}

.field-name-go-back-button {
  padding: 0 20px;
}

.arrow-link.back-arrow {
  margin-left: 20px;
  &:after {
    display: none;
  }
  &:before {
    position: absolute;
    content: "\e80c";
    left: -20px;
    font-family: fontello;
    transform: rotate(270deg);
  }
}

#block-menu-block-8 {
  margin: 20px auto;
  max-width: 1300px;

  > ul {
    padding: 20px;
  }

  > ul > li {
    padding-left: 0;
  }

  > ul > li {
    padding: 20px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 30px;
    background-color: #fbfbfb;
  }
}

.cross-background {
  background: url(../images/cross_background.png);

  .field-name-field-image {
    @include breakpoint($desktop) {
      position: absolute;
      left: 0;
    }
  }

  .paragraphs-item-image-title-and-text {
    @include breakpoint($desktop) {
      position: relative;
      float: none;
      width: 100%;
      padding-left: 200px;
    }
  }

}
.white-background {
  background: white;
}

//video::-webkit-media-controls-start-playback-button {
//  display: none !important;
//}


// ******************************* SPECIFIC PAGE SETTINGS *********************************
.path-digital-services-learning-multiply div.video-filter {
  @include breakpoint($desktop) {
    float: right;
    width: 50%;
    padding-bottom: 28%; // Half 56.25
    margin-left: 20px;
  }
}
