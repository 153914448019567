#nav {
  padding-top: 4px;
}
#block-menu-block-3 {
  display: none;
}

/* Media query START of: $small-only **********************************************/
@include breakpoint($mobile) {
  #nav {
    margin-top: 42px;
  }
  #nav > nav {
    display: none;
    background-color: #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid $color-dark-grey;

    li {
      padding: 10px 10px;
      display: block;
      font-weight: normal;
      transition: none;
    }
    a {
      display: block;
      font-weight: 600;
      color: $color-dark-grey;
      transition: none;
    }
    li:hover {
      background: $color-dark-grey;
      a {
        background: $color-dark-grey;
        color: white;
        text-decoration: none;
        transition: none;
      }
    }
    .active-trail {
      //background-color: #808285;
      //color: white;
    }
  }
}

/* Media query END of: $small-only ************************************************/

/* Media query START of: $medium-up **********************************************/
@include breakpoint($desktop) {

  .solid #nav > nav {
    margin-top: 40px;
    transition: margin 0.3s ease-in-out;
  }

  #nav > nav {
    padding-right: 15px;
    margin-top: 48px;
    transition: margin 0.3s ease-in-out;
    display: block !important;
    text-align: right;

    > ul {
      font-size: 14px;
      //letter-spacing: 0.15em;
      position: relative;

      a {
        margin: 0 8px;
        @include breakpoint($desktop-large) {
          margin: 0 16px;
        }

        @include breakpoint($desktop-huge) {
          margin: 0 28px;
        }

        display: inline-block;
        padding: 0;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        @include breakpoint($desktop-large) {
          font-size: 14px;
        }
     }
      a:hover {
        text-decoration: none;
        color: #808285;
      }

      a.active-trail {
        text-decoration: none;
        color: white;
        border-bottom: 4px solid #acaeaf;
        padding-bottom: 20px;
      }
      li {
        position: relative;
        display: inline;
      }

      li:first-child a {
        margin-left: 0;
      }
      li:last-child a {
        margin-right: 0;
      }
    }
  }

  .front #nav > nav > ul a.active-trail,
  .path-digital-services-learning-multiply #nav > nav > ul a.active-trail
  {
    border-bottom: none;
  }

}
/* Media query END of: $medium-up ************************************************/
