#footer-holder {
  position: relative;
  background: #222222;
  border-top: 8px solid #161616;
  line-height: 1.8em;

  padding: 50px 0 20px;
  @include breakpoint($desktop) {
    padding: 50px 0;
  }

  h2 {
    font-family: $font-body;
    font-size: 25px;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    &:after {
      display: block;
      content: "";
      width: 20px;
      padding-top: 22px;
      border-bottom: 1px solid white;
    }
    &:before {
      display: none;
    }
  }
  font-size: 16px;
  p {
    font-size: 16px;
  }

  //&:before {
  //  content: "";
  //  border-top: 1px solid #4e4e4e;
  //  position: absolute;
  //  top: 5px;
  //  left: 0;
  //  right: 0;
  //}
}

#block-block-2, #block-block-4 {
  padding: 0;

  flex: 0 1 50%;
  max-width: 50%;
  @include breakpoint($desktop-large) {
    flex: 0 1 22%;
  }
  align-self: auto;
  order: 0;

  > div, > h2 {
    padding: 0 15px;
  }
}

#block-block-4 {
  order: 1;
  @include breakpoint($desktop-large) {
    order: 2;
  }
  @include breakpoint($desktop) {
    > div, > h2 {
      padding-left: 0;
    }
  }
  > div, > h2 {
    padding-right: 15px;
  }
  a {
    font-weight: bold;
    border-bottom: 2px solid white;
  }
  a:hover {
    text-decoration: none;
  }
}

#block-block-2 {
  @include breakpoint($desktop) {
    order: 1;
  }
  @include breakpoint($desktop-large) {
    order: 0;
    padding-left: 15px;
  }
}

#block-block-3 {
  padding: 0 15px;

  .block-content {
    overflow: hidden;
    //word-break: break-all;
  }

  flex: 1 1 100%;
  align-self: auto;
  @include breakpoint($desktop) {
    padding: 0 15px 40px;
    order: 0;
  }
  @include breakpoint($desktop-large) {
    padding: 0 15px;
    flex: 1 0 50%;
    order: 1;
  }
}
#block-block-7 {
  display: none;
  padding: 0 15px;
  @include breakpoint($mobile) {
    display: block;
    img {
      max-width: 154px;
    }
  }
  @include breakpoint($desktop-large) {
    display: block;
    padding: 0 15px 0 0;
    width: 12.5%;
    float: left;
  }
}


#block-block-2, #block-block-3, #block-block-4 {
  margin-bottom: 30px;

  @include breakpoint($desktop) {
    margin-bottom: 0;
  }

  color: white;
  a, p {
    color: white;
  }
  p:last-child {
    margin-bottom: 0;
  }

  li:before, .spamspan:before, .telephone:before {
    content: "\e810";
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    padding-right: 7px;
    font-size: 15px;
  }

  .spamspan:before {
    content: "\e802";
  }
}


#subfooter {
  background: #161616;

  p {
    vertical-align: middle;
    color: white;
    padding: 0;
    width: 100%;
    text-align: center;

    @include breakpoint($desktop-large) {
      text-align: left;
      height: 76px;
      display: table-cell;
      width: 50%;
    }
  }
  p:first-child {
    padding-top: 20px;
    @include breakpoint($desktop-large) {
      padding-top: 0;
      width: 20%;
    }
  }
  p:last-child {
    display: none;
    @include breakpoint($desktop) {
      display: block;
      width: 100%;
    }
    @include breakpoint($desktop-large) {
      text-align: right;
      display: table-cell;
      width: 80%;
    }
  }

  a {
    color: $color-dark-grey;
  }
}

#block-block-5 .block-content {
  padding: 20px 10px 0;
  width: 100%;
  @include breakpoint($desktop) {
    display: table;
    padding: 0 15px;
  }
}


#block-block-6 {
  padding: 10px 0;

  @include breakpoint($desktop) {
    padding: 80px 0;
  }
  text-align: center;
  img {
    margin: 10px 3%;
    @include breakpoint($desktop) {
      margin: 0 3%;
    }
  }
  img:nth-child(3) {
    @include breakpoint($mobile) {
      clear: both;
      display: block;
      margin: 0 auto;
    }
  }
  p {
    margin: 0;
  }
}

#footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  .places {
    @include clearfix;
  }

  .placestext a {
    font-weight: 700;
    border-bottom: 2px solid white;
    &:hover {
      text-decoration: none;
    }
  }

  .place-manchester {
    //@include breakpoint($desktop) {
      padding-bottom: 20px;
    //}
  }
  .place-edinburgh, .place-london, .place-manchester {
    @include breakpoint($desktop) {
      float: left;
      width: 50%;
      padding-right: 20px;
    }

    @include breakpoint($desktop-huge) {
      float: left;
      width: 33%;
      padding-right: 20px;
    }
  }
  .city {
    font-weight: bold;
    text-transform: uppercase;
  }

  .place-manchester {
    @include breakpoint($desktop) {
      clear: left;
    }
    @include breakpoint($desktop-huge) {
      clear: none;
    }
  }

  .telephone:before {
    content: "\e800";
  }
}

.path-node-148-done,
.path-node-150-done {

  #main-content {
    text-align:center;
    max-width:12--px;
    margin: 0 auto;
    padding: 10%;
  }
  .links {
    display:none;
  }
}